<template>
  <div class="flex flex-col text-gray-700 bg-white px-4 py-4 rounded-sm shadow">
    <div class="flex-1 flex-auto py-2 font-bold">
      <img class src="../assets/img/xcm_logo.png" alt="XCM Logo" />
    </div>
    <div class="flex-1 flex-auto py-2 font-bold">
      <h1>{{this.nextRoute === '' ? 'Passwort eingeben für den Upload' : 'Passwort eingeben für den Download: '+this.$route.query.dl }}</h1>
    </div>
    <div class="flex flex-row py-2">
      <div class="flex-auto rounded mr-4">
        <input
          v-model="password"
          v-on:keyup.enter="SIGN_IN"
          type="password"
          placeholder="Passwort bitte..."
          class="flex w-full block px-2 py-2 bg-gray-600 rounded-sm placeholder-blue-100 focus:bg-blue-200 focus:outline-none focus:placeholder-blue-600 focus:text-gray-700 focus:shadow-inner"
        />
      </div>
      <div class="flex-grow-1 rounded">
        <button
          v-on:click.prevent="SIGN_IN"
          class="flex px-4 py-2 rounded-sm bg-blue-300 text-gray-800 hover:bg-blue-400 hover:text-gray-900 shadow-md hover:shadow-lg focus:outline-none"
        >Login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      password: "",
      nextRoute: this.$route.query.dl || ""
    };
  },
  methods: {
    SIGN_IN: function() {
      const { password, nextRoute } = this;
      this.$store.dispatch("SIGN_IN", {
        password: password,
        nextRoute: nextRoute
      });
      this.password = "";
    }
  }
};
</script>
